<template lang="">
  <div class="py-6 px-6 mt-2 w-full h-full bg-white rounded shadow ">
    <div class="flex justify-between items-center mb-6">
      <div :class="typographyOne">
        ROLE <span v-if="isRawDataOneMore">{{ rawDataIndex + 1 }}</span
        >: {{ rawData.role_name }}
      </div>
      <oto-edit-button
        v-if="editPermissionStatus"
        variant="gray"
        @click="$emit('edit-role')"
      />
    </div>
    <!-- start: details-section -->
    <div class="grid" :class="gridClass" v-if="rawData.permissions">
      <div
        class="grid grid-cols-12 my-3"
        v-for="(item, index) in rawData.permissions"
        :key="index"
      >
        <div class="col-span-3" :class="typographyTwo">
          {{ item.name }}
        </div>

        <div class="col-span-9">
          <div class="flex gap-1 md:gap-2">
            <div
              :key="childItemIndex"
              v-for="(childItem, childItemIndex) in item.action_types"
            >
              <p
                class="border-2 px-1.5 lg:px-3 py-1 border-gray-400 rounded-full flex items-center justify-center text-xs md:text-sm"
              >
                {{ childItem }}
              </p>
            </div>
          </div>
        </div>

        <div :class="typographyThree"></div>
      </div>
    </div>
    <div v-if="rawData.permissions.length === 0" class="text-center">
      <p>
        {{
          $t(
            'components.orgUserDetailsManagement.diveIntoTab.profile.grid.rolesInfo.value.noPermissionFound'
          )
        }}
      </p>
    </div>
    <!-- start: details-section -->
  </div>
</template>
<script>
import OtoEditButton from '@/components/ui/OtoEditButton'
export default {
  name: 'RoleInfoGrid',
  components: { OtoEditButton },
  props: {
    rawDataIndex: {
      type: Number,
      required: true,
    },
    rawData: {
      type: Object,
      required: true,
    },
    isRawDataOneMore: {
      type: Boolean,
      default: false,
      required: false,
    },
    editPermissionStatus: {
      type: Boolean,
      default: false,
      required: false,
    },
    gridClass: {
      type: String,
      default: 'grid-cols-1 md:grid-cols-2',
      required: false,
    },
  },
  data() {
    return {
      // title
      typographyOne: 'font-semibold uppercase text-oDark text-xl',
      // subtitle
      typographyTwo: 'font-semibold text-14px text-oDark',
      // subtitle->value
      typographyThree: 'font-medium text-14px text-gray-500',
    }
  },
}
</script>
<style lang=""></style>
