var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('UserAddEdit',{attrs:{"es-id":_vm.ae.esId,"stepper-mode":_vm.ae.stepperMode,"stepper-step":_vm.ae.stepperStep,"user-id":_vm.ae.userId,"user-data":_vm.ae.userData}}),_c('RoleAddEdit',{on:{"refresh":_vm.fetchData}}),_c('info-grid',{attrs:{"title":_vm.$t(
        'components.orgUserDetailsManagement.diveIntoTab.profile.grid.basicInfo.gridTitle'
      ),"grid-class":"lg:grid-cols-2","type":"basic","data":_vm.getProfile(),"edit-button":true},on:{"edit":_vm.handleEditing},scopedSlots:_vm._u([{key:"footer",fn:function(ref){
      var typographyTwo = ref.typographyTwo;
      var typographyThree = ref.typographyThree;
return [_c('div',{staticClass:"grid w-1/2 grid-cols-2 ml-auto"},[_c('div',{class:typographyTwo},[_vm._v(" "+_vm._s(_vm.$t( 'components.orgUserDetailsManagement.diveIntoTab.profile.grid.basicInfo.infoTitle.fleets' ))+" ")]),_c('div',_vm._l((_vm.getFleets()),function(item,index){return _c('div',{key:index,class:typographyThree},[_vm._v(" "+_vm._s(item)+" ")])}),0)])]}}])}),(_vm.isRoleAvailable)?_c('div',{staticClass:"relative role-box "},[(_vm.isRoleDataLoading)?_c('loading',{class:"outline-none",attrs:{"active":_vm.isRoleDataLoading,"height":25,"opacity":0.5,"color":"#000","loader":"bars","is-full-page":false}}):_c('div',[(_vm.rolesAndPermissionsDetails.length)?_c('div',[_vm._l((_vm.rolesAndPermissionsDetails),function(item,itemIndex){return [_c('RoleInfoGrid',{key:itemIndex,attrs:{"rawDataIndex":itemIndex,"isRawDataOneMore":_vm.rolesAndPermissionsDetails.length > 1,"editPermissionStatus":_vm.$acl.canUpdate('users'),"rawData":item},on:{"edit-role":function($event){return _vm.handleRoleEdit(item)}}})]})],2):_vm._e()])],1):_vm._e(),_c('div',{staticClass:"grid md:grid-cols-2 sm:grid-cols-1 md:space-x-2 sm:space-x-0"},[_c('div',{staticClass:"grid h-full grid-cols-1 mt-2 space-x-0 space-y-0 bg-white rounded"},[_c('info-grid',{attrs:{"title":_vm.$t(
            'components.orgUserDetailsManagement.diveIntoTab.profile.grid.permanentAddress.gridTitle'
          ),"type":"address","grid-class":"h-auto lg:grid-cols-1","data":_vm.getPermanentAddress()},on:{"edit":_vm.handleEditing}}),_c('info-grid',{attrs:{"title":_vm.$t(
            'components.orgUserDetailsManagement.diveIntoTab.profile.grid.presentAddress.gridTitle'
          ),"data":_vm.getPresentAddress(),"grid-class":"h-auto lg:grid-cols-1","type":"address"},on:{"edit":_vm.handleEditing}})],1),_c('div',{staticClass:"grid grid-cols-1 space-x-0 space-y-0"},[_c('info-grid',{attrs:{"title":_vm.$t(
            'components.orgUserDetailsManagement.diveIntoTab.profile.grid.paymentInfo.gridTitle'
          ),"type":"payment","grid-class":"lg:grid-cols-1 ","data":_vm.getPaymentInfo()},on:{"edit":_vm.handleEditing}})],1),_c('div',{staticClass:"grid h-full mb-2 lg:grid-cols-1 md:space-x-2 md:pr-2 md:grid-cols-2"})]),_c('content-section',{staticClass:"mt-2"},[_c('div',{staticClass:"flex items-center justify-between"},[_c('oto-typography',{attrs:{"text":_vm.$t(
            'components.orgUserDetailsManagement.diveIntoTab.profile.grid.documents.headline'
          ),"variant":"one"}}),_c('oto-edit-button',{on:{"click":_vm.handleEditing}})],1),_c('div',{staticClass:"h-px my-4 bg-oLightestGray"}),(_vm.getNidDocsCount > 0)?_c('div',[_c('user-document',{attrs:{"status":_vm.isNidVerified,"cover":_vm.getNidCover,"images":_vm.getNidDocs,"title":_vm.$t(
            'components.orgUserDetailsManagement.diveIntoTab.profile.grid.documents.nationalId'
          ),"subtitle":"SL: 123786881"},on:{"verify":function($event){return _vm.verifyDocs('nid')}}})],1):_c('div',{staticClass:"text-center text-oGray"},[_vm._v(" "+_vm._s(_vm.$t( 'components.orgUserDetailsManagement.diveIntoTab.profile.grid.documents.noDocs' ))+" ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }