<template>
  <div>
    <UserAddEdit
      :es-id="ae.esId"
      :stepper-mode="ae.stepperMode"
      :stepper-step="ae.stepperStep"
      :user-id="ae.userId"
      :user-data="ae.userData"
    />
    <RoleAddEdit @refresh="fetchData" />
    <info-grid
      :title="
        $t(
          'components.orgUserDetailsManagement.diveIntoTab.profile.grid.basicInfo.gridTitle'
        )
      "
      grid-class="lg:grid-cols-2"
      type="basic"
      :data="getProfile()"
      :edit-button="true"
      v-on:edit="handleEditing"
    >
      <template v-slot:footer="{ typographyTwo, typographyThree }">
        <div class="grid w-1/2 grid-cols-2 ml-auto">
          <div :class="typographyTwo">
            {{
              $t(
                'components.orgUserDetailsManagement.diveIntoTab.profile.grid.basicInfo.infoTitle.fleets'
              )
            }}
          </div>

          <div>
            <div
              v-for="(item, index) in getFleets()"
              :key="index"
              :class="typographyThree"
            >
              {{ item }}
            </div>
          </div>
        </div>
      </template>
    </info-grid>

    <div v-if="isRoleAvailable" class="relative role-box ">
      <loading
        v-if="isRoleDataLoading"
        :active="isRoleDataLoading"
        :height="25"
        :opacity="0.5"
        :color="`#000`"
        :loader="`bars`"
        :is-full-page="false"
        :class="`outline-none`"
      />

      <div v-else>
        <div v-if="rolesAndPermissionsDetails.length">
          <template v-for="(item, itemIndex) in rolesAndPermissionsDetails">
            <RoleInfoGrid
              :key="itemIndex"
              :rawDataIndex="itemIndex"
              :isRawDataOneMore="rolesAndPermissionsDetails.length > 1"
              :editPermissionStatus="$acl.canUpdate('users')"
              :rawData="item"
              @edit-role="handleRoleEdit(item)"
            />
          </template>
        </div>
      </div>
    </div>

    <div class="grid md:grid-cols-2 sm:grid-cols-1 md:space-x-2 sm:space-x-0">
      <div
        class="grid h-full grid-cols-1 mt-2 space-x-0 space-y-0 bg-white rounded"
      >
        <info-grid
          :title="
            $t(
              'components.orgUserDetailsManagement.diveIntoTab.profile.grid.permanentAddress.gridTitle'
            )
          "
          type="address"
          grid-class="h-auto lg:grid-cols-1"
          :data="getPermanentAddress()"
          v-on:edit="handleEditing"
        />
        <info-grid
          :title="
            $t(
              'components.orgUserDetailsManagement.diveIntoTab.profile.grid.presentAddress.gridTitle'
            )
          "
          :data="getPresentAddress()"
          grid-class="h-auto lg:grid-cols-1"
          type="address"
          v-on:edit="handleEditing"
        />
      </div>
      <div class="grid grid-cols-1 space-x-0 space-y-0">
        <info-grid
          :title="
            $t(
              'components.orgUserDetailsManagement.diveIntoTab.profile.grid.paymentInfo.gridTitle'
            )
          "
          type="payment"
          grid-class="lg:grid-cols-1 "
          :data="getPaymentInfo()"
          v-on:edit="handleEditing"
        />
      </div>
      <div
        class="grid h-full mb-2 lg:grid-cols-1 md:space-x-2 md:pr-2 md:grid-cols-2"
      ></div>
    </div>

    <content-section class="mt-2">
      <div class="flex items-center justify-between">
        <oto-typography
          :text="
            $t(
              'components.orgUserDetailsManagement.diveIntoTab.profile.grid.documents.headline'
            )
          "
          variant="one"
        />
        <oto-edit-button @click="handleEditing" />
      </div>
      <div class="h-px my-4 bg-oLightestGray" />
      <div v-if="getNidDocsCount > 0">
        <user-document
          :status="isNidVerified"
          :cover="getNidCover"
          :images="getNidDocs"
          :title="
            $t(
              'components.orgUserDetailsManagement.diveIntoTab.profile.grid.documents.nationalId'
            )
          "
          subtitle="SL: 123786881"
          @verify="verifyDocs('nid')"
        />
      </div>
      <div v-else class="text-center text-oGray">
        {{
          $t(
            'components.orgUserDetailsManagement.diveIntoTab.profile.grid.documents.noDocs'
          )
        }}
      </div>
    </content-section>
  </div>
</template>

<script>
import xMan from '@/utils/xMan'
import { OrganizationUserConfig } from '@/config/OrganizationUserConfig'
import InfoGrid from '@/components/layout/InfoGrid'
import RoleInfoGrid from '@/components/layout/RoleInfoGrid.vue'
import ContentSection from '@/components/layout/ContentSection.vue'
import OtoTypography from '@/components/ui/OtoTypography'
import OtoEditButton from '@/components/ui/OtoEditButton'
import dayjs from 'dayjs'
import UserDocument from '@/components/ui/UserDocument'
import RoleAddEdit from '@/views/acl/RoleAddEdit.vue'
import { EventBus } from '@/utils/EventBus'
import UserAddEdit from '@/views/user/organization/UserAddEdit'
import { useEndpoints } from '@/composables'
import { RoleConfig } from '@/config/RoleConfig'
export default {
  name: 'ViewOrganizationUserProfile',
  components: {
    InfoGrid,
    RoleInfoGrid,
    ContentSection,
    OtoTypography,
    OtoEditButton,
    UserDocument,
    UserAddEdit,
    RoleAddEdit,
  },
  data() {
    return {
      isRoleAvailable: false,
      isRoleDataLoading: true,
      org_user: {},
      fallbackText: 'N/A',
      avatar: '',
      rolesAndPermissionsDetails: [],
      profile: {
        address_info: {
          current_address_country: '',
          current_address_full: '',
          current_address_zip_code: '',
          permanent_address_country: '',
          permanent_address_full: '',
          permanent_address_zip_code: '',
        },
        assigned_fleets: [],
        document_info: '',
        email: '',
        full_name: '',
        id: '',
        organization_id: '',
        payment_info: {
          account_name: '',
          account_number: '',
          branch_name: '',
          country: '',
          id: '',
          routing_number: '',
        },
        phone_number: '',
        profile: {
          birthday: '',
          gender: '',
          profile_pic: '',
          ranger_job_type: '',
        },
        role: {},
        roles: [],
        user_status: '',
      },
      s: {
        id: 'f6e30e7c-64f6-4769-aa96-2225a86ab5bf',
        role: {
          id: 3,
          role_name: 'Franchise',
          created_by: {
            id: 'b3076499-c424-480b-bc9c-3a835a66f9fc',
            full_name: 'John Doe',
            role_name: 'Owner',
          },
          created_at: '2020-12-04 18:10:09',
        },
        organization_id: '05cec94f-4bfa-4a89-9360-19ef1ab18a13',
        full_name: 'Robert Schoen',
        phone_number: '854-402-4013',
        email: 'Mitchel37@yahoo.com',
        user_status: 'A',
        invitation_code: 'OOWNKR',
        profile: {
          profile_pic:
            'http://via.placeholder.com/300/e0ddef/6454b5?text=ROBERTSCHOEN',
          gender: null,
          ranger_job_type: null,
          birthday: null,
          height: null,
          weight: null,
          balance: '0.00',
          num_of_trips: 0,
          total_calories: '0.00',
          earning_from_invitations: 0,
        },
        assigned_fleets: [],
        payment_info: null,
        document_info: null,
        address_info: {
          current_address_country: null,
          current_address_full: null,
          current_address_zip_code: null,
          permanent_address_country: null,
          permanent_address_full: null,
          permanent_address_zip_code: null,
        },
      },
      // light-box
      nid_index: null,
      driving_index: null,
      // AddEdit (ae)
      ae: {
        // EdgeStack component
        esId: 'user-add-edit',
        // XStepper component
        stepperMode: 'free',
        stepperStep: 1,
        // Data
        data: {},
        userId: null,
        userData: null,
      },
    }
  },
  computed: {
    id() {
      return this.$route.params.id
    },
    getBalance() {
      return this.profile.profile.balance
        ? '$' + this.profile.profile.balance
        : '$0.00'
    },
    getTotalRides() {
      return this.profile.profile.num_of_trips || '0'
    },
    getTotalCalories() {
      return this.profile.profile.total_calories || '0.00'
    },
    getTotalEarnings() {
      return this.profile.profile.earning_from_invitations
        ? '$' + this.profile.profile.earning_from_invitations
        : '$0.00'
    },
    getHeight() {
      return this.profile.profile.height || this.fallbackText
    },
    getWeight() {
      return this.profile.profile.weight || this.fallbackText
    },
    getReferralCode() {
      return this.profile.invitation_code || ''
    },
    getBirthday() {
      if (this.profile.profile && this.profile.profile.birthday) {
        return dayjs(this.profile.profile.birthday).format('DD MMM, YYYY')
      }
      return 'N/A'
    },
    isNidVerified() {
      return this.profile.document_info
        ? this.profile.document_info.is_verified
        : false
    },
    getNidDocsCount() {
      let count = 0
      if (!this.profile.document_info) {
        return count
      }
      if (this.profile.document_info.nid_front_side_pic) {
        count += 1
      }
      if (this.profile.document_info.nid_back_side_pic) {
        count += 1
      }
      return count
    },
    getNidDocs() {
      return [
        {
          title: 'NID Documents',
          description: 'NID Front View',
          // todo: add fallback image
          src: this.profile.document_info
            ? this.profile.document_info.nid_front_side_pic
            : null,
        },
        {
          title: 'NID Documents',
          description: 'NID Rear View',
          // todo: add fallback image
          src: this.profile.document_info
            ? this.profile.document_info.nid_back_side_pic
            : null,
        },
      ]
    },
    getNidCover() {
      return this.profile.document_info
        ? this.profile.document_info.nid_front_side_pic
        : null
    },
    hasDrivingLicense() {
      return this.profile.document_info
        ? this.profile.document_info.has_driving_license
        : null
    },
    getDrivingLicense() {
      return [
        {
          title: 'Driving License',
          description: '',
          // todo: add fallback image
          src: this.profile.document_info.driving_license_img,
        },
      ]
    },
  },
  created() {
    this.fetchData()
  },
  watch: {
    profile: {
      deep: true,
      immediate: true,
      handler(updatedData) {
        if (updatedData.roles.length) {
          this.isRoleAvailable = true
          this.isRoleDataLoading = true
          const roleReqs = []
          updatedData.roles.forEach((item) => {
            const roleReq = this.$http.get(useEndpoints.role.details(item.id))
            roleReqs.push(roleReq)
          })
          this.$http
            .all(roleReqs)
            .then(
              this.$http.spread((...responses) => {
                responses.forEach((item) =>
                  this.rolesAndPermissionsDetails.push(item.data)
                )

                console.log('roles-r', this.rolesAndPermissionsDetails)
              })
            )
            .finally(() => (this.isRoleDataLoading = false))
        }
      },
    },
  },
  methods: {
    async fetchData() {
      this.rolesAndPermissionsDetails = []
      await this.$http
        .get(OrganizationUserConfig.api.single(this.id))
        .then((res) => {
          console.log('org-user-profile', res)
          this.ae.userData = res.data
          this.ae.userId = res.data.id
          this.profile = { ...res.data }
          this.avatar = res.data.profile.profile_pic
        })
        .catch((err) => {
          console.log('org-user-profile-err', err)
        })
    },
    getProfile() {
      let data = []
      data.push({
        text: this.$t(
          'components.orgUserDetailsManagement.diveIntoTab.profile.grid.basicInfo.infoTitle.fullName'
        ),
        value: this.profile.full_name || this.fallbackText,
      })
      data.push({
        text: this.$t(
          'components.orgUserDetailsManagement.diveIntoTab.profile.grid.basicInfo.infoTitle.phoneNumber'
        ),
        value: this.profile.phone_number || this.fallbackText,
      })
      data.push({
        text: this.$t(
          'components.orgUserDetailsManagement.diveIntoTab.profile.grid.basicInfo.infoTitle.dateOfBirth'
        ),
        value: this.getBirthday,
      })
      data.push({
        text: this.$t(
          'components.orgUserDetailsManagement.diveIntoTab.profile.grid.basicInfo.infoTitle.jobType'
        ),
        value: this.getJobType(this.profile.profile.ranger_job_type),
      })
      data.push({
        text: this.$t(
          'components.orgUserDetailsManagement.diveIntoTab.profile.grid.basicInfo.infoTitle.email'
        ),
        value: this.profile.email || this.fallbackText,
      })
      data.push({
        text: this.$t(
          'components.orgUserDetailsManagement.diveIntoTab.profile.grid.basicInfo.infoTitle.gender'
        ),
        value: this.getGender(this.profile.profile.gender),
      })
      return data
    },
    getFleets() {
      // console.warn(this.assigned_fleets);
      let data = []
      if (this?.profile?.assigned_fleets?.length) {
        data = this.profile.assigned_fleets.map((x) => {
          return x.name
        })
      }
      if (data.length === 0) {
        data.push('--')
      }
      console.log({ fleets: data })
      return data
    },
    getPermanentAddress() {
      // todo: use xMan.asteriskify() for sensitive data
      let data = []
      data.push({
        text: this.$t(
          'components.orgUserDetailsManagement.diveIntoTab.profile.grid.permanentAddress.infoTitle.country'
        ),
        value:
          this.profile.address_info.permanent_address_country ||
          this.fallbackText,
      })
      data.push({
        text: this.$t(
          'components.orgUserDetailsManagement.diveIntoTab.profile.grid.permanentAddress.infoTitle.postalOrZip'
        ),
        value:
          this.profile.address_info.permanent_address_zip_code ||
          this.fallbackText,
      })
      data.push({
        text: this.$t(
          'components.orgUserDetailsManagement.diveIntoTab.profile.grid.permanentAddress.infoTitle.address'
        ),
        value:
          this.profile.address_info.permanent_address_full || this.fallbackText,
      })
      return data
    },
    getPresentAddress() {
      // todo: use xMan.asteriskify() for sensitive data
      let data = []
      data.push({
        text: this.$t(
          'components.orgUserDetailsManagement.diveIntoTab.profile.grid.presentAddress.infoTitle.country'
        ),
        value:
          this.profile.address_info.current_address_country ||
          this.fallbackText,
      })
      data.push({
        text: this.$t(
          'components.orgUserDetailsManagement.diveIntoTab.profile.grid.presentAddress.infoTitle.postalOrZip'
        ),
        value:
          this.profile.address_info.current_address_zip_code ||
          this.fallbackText,
      })
      data.push({
        text: this.$t(
          'components.orgUserDetailsManagement.diveIntoTab.profile.grid.presentAddress.infoTitle.address'
        ),
        value:
          this.profile.address_info.current_address_full || this.fallbackText,
      })
      return data
    },
    getPaymentInfo() {
      // todo: use xMan.asteriskify() for sensitive data
      let data = []
      data.push({
        text: this.$t(
          'components.orgUserDetailsManagement.diveIntoTab.profile.grid.paymentInfo.infoTitle.bankAccountName'
        ),
        value: this.profile.payment_info
          ? this.profile.payment_info.account_name
          : this.fallbackText,
      })
      data.push({
        text: this.$t(
          'components.orgUserDetailsManagement.diveIntoTab.profile.grid.paymentInfo.infoTitle.bankAccountNo'
        ),
        value: this.profile.payment_info
          ? this.profile.payment_info.account_number
          : this.fallbackText,
      })
      data.push({
        text: this.$t(
          'components.orgUserDetailsManagement.diveIntoTab.profile.grid.paymentInfo.infoTitle.branch'
        ),
        value: this.profile.payment_info
          ? this.profile.payment_info.branch_name
          : this.fallbackText,
      })
      data.push({
        text: this.$t(
          'components.orgUserDetailsManagement.diveIntoTab.profile.grid.paymentInfo.infoTitle.country'
        ),
        value: this.profile.payment_info
          ? this.profile.payment_info.country
          : this.fallbackText,
      })
      data.push({
        text: this.$t(
          'components.orgUserDetailsManagement.diveIntoTab.profile.grid.paymentInfo.infoTitle.routingNumber'
        ),
        value: this.profile.payment_info
          ? this.profile.payment_info.routing_number
          : this.fallbackText,
      })
      return data
    },
    handleEditing(step) {
      console.log('step', step)
      var item = null
      var steps = -1
      this.$edgeStack.open(this.ae.esId)
      if (step == 'basic') {
        item = {
          id: this.id,
        }
        this.ae.stepperStep = 1
      } else if (step == 'address') {
        item = {
          id: this.id,
          step: 4,
        }
        this.ae.stepperStep = 4
      } else if (step == 'payment') {
        item = {
          id: this.id,
          step: 2,
        }
        this.ae.stepperStep = 2
      } else {
        item = {
          id: this.id,
          step: 3,
        }
        this.ae.stepperStep = 3
      }
      this.$store.dispatch('orguser/saveStep', {
        step: steps == -1 ? 1 : steps,
      })

      EventBus.$emit(OrganizationUserConfig.events.editingData, item)
      dispatchEvent(new Event(OrganizationUserConfig.events.sorToggle))
      //alert("editing triggered = " , step);
    },
    handleRoleEdit(item) {
      EventBus.$emit(RoleConfig.events.editingData, item)
      dispatchEvent(new Event(RoleConfig.events.sorToggle))
    },
    handleDownloading() {
      alert('downloading triggered')
    },
    showPopup: function(type) {
      if (type === 'nid') {
        this.nid_index = 0
        return
      }
      if (type === 'driving') {
        this.driving_index = 0
        return
      }
    },
    getDropdownClass(type) {
      if (type === 'nid') {
        if (this.isNidVerified) {
          return 'nid-verified'
        }
        return 'nid-unverified'
      }
    },
    getDropdownText(type) {
      if (type === 'nid') {
        return this.isNidVerified ? 'Verified' : 'Unverified'
      }
    },
    getJobType(job) {
      if (job) {
        return job === 'FULLTIME' ? 'Full Time' : 'Part Time'
      }
      return 'N/A'
    },
    getGender(gender) {
      if (gender) {
        return gender === 'M' ? 'Male' : 'Female'
      }
      return 'N/A'
    },
    async verifyDocs(type) {
      // alert("sf");
      if (type === 'nid') {
        this.isLoading = true
        let data = new xMan({ is_verified: true }).toFormData()

        // let mfromData = new FormData();
        // mformData.append("is_verified", true);
        console.log('fs=', data)
        await this.$http
          .post(OrganizationUserConfig.api.verifyDocs(this.id), data)
          .then((res) => {
            console.log(res)
            this.$notify(
              {
                group: 'generic',
                type: 'success',
                title: 'Success',
                text: 'Verified',
              },
              3000
            )
          })
          .catch((err) => {
            console.log(err)
            this.$notify(
              {
                group: 'generic',
                type: 'error',
                title: 'Failed',
                text: 'Failed',
              },
              3000
            )
          })
          .finally(() => {
            this.isLoading = false
          })
        this.profile.document_info.is_verified = true
        return true
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.docs-dropdown-button {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  width: 110px;
  height: 18px;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 500;
}
.nid-verified {
  background-color: #09aa35;
}
.nid-unverified {
  background-color: #f67606;
}
.role-box {
  min-height: 3rem;
}
</style>
